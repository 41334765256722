/* src/gallery.css */

.gallery-container {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../images/stars-overlay.jpg') no-repeat center center/cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-heading {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 40px;
  font-weight: 700;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
}

/* Gallery grid layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Auto-fill the grid */
  gap: 20px; /* Space between images */
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}

/* Gallery card container with fixed size */
.card-container {
  width: 250px; /* Fixed size */
  height: 350px; /* Fixed size */
  background-color: rgba(44, 42, 42, 0.6);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* Image styling */
.gallery-image {
  width: 100%;
  height: 100%; /* Now using the full height of the card */
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

/* Hover effect on the image */
.card-container:hover .gallery-image {
  transform: scale(1.05); /* Scale up by 5% on hover */
}

/* Send Drawing Button Container */
.send-drawing-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; /* Space above the button */
}

/* Button Styling */
.send-button {
  background: linear-gradient(135deg, #4e73df, #6cb2eb); /* Gradient from blue to lighter blue */
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
  border: none;
  border-radius: 30px; /* More rounded corners for a polished look */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Soft shadow effect for depth */
  cursor: pointer;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1px; /* Slightly more spacing between letters for a cleaner look */
  outline: none;
  display: inline-block;
}

/* Hover Effect */
.send-button:hover {
  background: linear-gradient(135deg, #6cb2eb, #4e73df); /* Reverse gradient on hover */
  transform: translateY(-4px); /* Slightly lift the button */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover for added depth */
}

/* Focus Effect */
.send-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(72, 161, 255, 0.6); /* Soft focus outline effect */
}

/* Disabled State */
.send-button:disabled {
  background: #c0c0c0;
  cursor: not-allowed;
  box-shadow: none;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.modal-image {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

/* Close modal button */
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5c5c;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-modal:hover {
  background-color: #d9534f;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: 1fr; /* Stack images on mobile */
  }
  .send-button {
    padding: 10px 25px; /* Slightly smaller padding on mobile */
    font-size: 14px; /* Slightly smaller text on mobile */
  }
}
