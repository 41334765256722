/* Header/Nav component styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000; /* Dark background for navbar */
  color: #fff; /* Light text color */
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure navbar is above all other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
  
  .navbar-logo > .logologo {
    margin-left: 20px;
    height: 48px;
    cursor: pointer;
  }
  
  .logo-text {
    height: 50px;
    filter: invert(1); /* Makes the logo text white */
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    list-style: none;
  }
  
  .navbar-links li {
    margin: 0 1rem;
  }
  
  .navbar-links a {
    color: #ddd;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .navbar-btn .sign-up {
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navbar-btn .login {
    text-decoration: none;
  }
  
  .navbar-btn .sign-up:hover {
    background-color: #0056b3;
  }
  
  .navbar-links a {
    position: relative;
    color: #ddd;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.25rem 0;
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #fff;
  }
  
  .navbar-links a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px; 
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
  
  .navbar-links a:hover::after {
    width: 100%;
  }
  
  /* Styling for the new Login button */
  .navbar-btn .login {
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navbar-btn .login:hover {
    background-color: #218838;
  }
  
 
  /* Styling for the user's name */
  .navbar-btn .user-name {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff; /* White text color */
    margin-right: 1rem; /* Add some space between the name and the logout button */
  }
  
  /* Styling for the Logout button */
  .navbar-btn .logout-btn {
    padding: 0.5rem 1rem;
    background-color: #dc3545; /* Red color */
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease;
    font-weight: bold;
  }
  
  .navbar-btn .logout-btn:hover {
    background-color: #c82333; /* Darker red on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  /* Smooth fade-in effect for overlay */
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  /* Smooth slide-down effect for modal content */
  @keyframes slideIn {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  

 