

/* OTP Verification Container */
.otp-verification-container {
  max-width: 400px;
  width: 100%; /* Ensure it takes full width on smaller screens */
  margin: 3rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box; /* Prevents padding from affecting width */
  overflow: hidden; /* Ensure no overflow occurs */
}

/* Header */
.otp-verification-container h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
}

/* OTP Instructions */
.otp-instructions {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.5rem;
}

/* OTP Input Container */
.otp-input-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

/* OTP Input Fields */
.otp-input {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #f9f9f9;
}

.otp-input:focus {
  border-color: #007bff;
  background-color: #fff;
}

/* Submit Button */
.submit-btn {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Resend OTP Link */
.resend-link {
  display: block;
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.resend-link:hover {
  text-decoration: underline;
}

/* Success Message and Tick Mark */
.success-message {
  text-align: center;
  margin-top: 2rem;
}

.success-message p {
  font-size: 1.2rem;
  color: #4CAF50; /* Green color for success message */
  margin-top: 1rem;
}

/* Tick Mark Animation */
.tick-mark {
  font-size: 4rem;
  color: #4CAF50;
  animation: tickAnimation 1s ease-out forwards;
}

@keyframes tickAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 500px) {
  .otp-verification-container {
    max-width: 100%;
    padding: 1.5rem;
  }

  .otp-input {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
  }

  .submit-btn {
    font-size: 0.9rem;
    padding: 0.75rem 1rem;
  }
}
