@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* src/components/Quiz.css */

/* Dark Theme - General styling */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

/* Main quiz container */
.quizApp {
  background-color: rgba(30, 30, 30, 0.8); /* Slightly transparent dark background */
  background-image: url('../images/stars-overlay.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(205, 203, 203, 0.4);
  max-width: 800px;
  margin: 40px auto;
  color: #e0e0e0;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

/* Title styling */
.quizAppTitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 30px;
}

/* Question styling */
.quizAppQuestion {
  font-size: 1.3rem;
  margin-bottom: 25px;
}

/* Option buttons container */
.quizAppOptions {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

/* Styling for individual option buttons with color variations */
.quizAppOptionButton {
  background-color: #4e73df; /* Muted blue for the primary option */
  color: white;
  font-size: 1.1rem;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

/* Smooth hover effect for all buttons */
.quizAppOptionButton:hover {
  background-color: #2c3e50; /* Darker blue/gray on hover */
  transform: translateY(-3px); /* Subtle lift effect */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

/* Styling for different colored options */
.quizAppOptionButton:nth-child(2) {
  background-color: #38a1db; /* Light blue for second option */
}

.quizAppOptionButton:nth-child(2):hover {
  background-color: #1c8ab7; /* Darker blue on hover */
}

.quizAppOptionButton:nth-child(3) {
  background-color: #28a745; /* Muted green for third option */
}

.quizAppOptionButton:nth-child(3):hover {
  background-color: #218838; /* Darker green on hover */
}

.quizAppOptionButton:nth-child(4) {
  background-color: #e67e22; /* Muted orange for fourth option */
}

.quizAppOptionButton:nth-child(4):hover {
  background-color: #d35400; /* Darker orange on hover */
}

/* Score display */
.quizAppScore {
  margin-top: 30px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Reset button styling */
.resetButton {
  background-color: #e53935; /* Muted red */
  color: white;
  font-size: 1rem;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 30%;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.resetButton:hover {
  background-color: #c62828; /* Darker red on hover */
  transform: translateY(-3px); /* Subtle lift effect */
}

.resetButton:focus {
  outline: none;
}

/* Responsive styling */
@media (min-width: 768px) {
  .quizAppOptions {
    grid-template-columns: repeat(2, 1fr);
  }

  .quizAppOptionButton {
    font-size: 1.2rem;
    padding: 16px;
  }
}

@media (min-width: 1024px) {
  .quizApp {
    padding: 50px;
  }

  .quizAppTitle {
    font-size: 3rem;
  }

  .quizAppQuestion {
    font-size: 1.4rem;
  }

  .quizAppOptionButton {
    font-size: 1.3rem;
    padding: 18px;
  }

  .quizAppScore {
    font-size: 1.3rem;
  }

  .resetButton {
    font-size: 1.4rem;
  }
}
