/* Hero section styling */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensure the hero section fills the viewport height */
  color: #fff;
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  text-align: center; /* Center content horizontally */
}

/* Background container for smooth transition */
.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

/* Background images */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

.background-image.current {
  opacity: 1; /* Full opacity for current image */
}

.background-image.next {
  opacity: 0; /* Initially hidden */
}

/* Dark overlay for better content visibility */
.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  text-align: center; /* Center content horizontally */
}

.hero-content h1 {
  font-size: 4.5rem; /* Larger font for large screens */
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
}

.hero-content p {
  font-size: 1.25rem;
  max-width: 800px;
  line-height: 1.6;
  font-weight: bold;
  margin-bottom: 30px; /* Add some space between text and button */
}

.explore-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgba(94, 159, 233, 0.043);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  border: 0.25px solid #fff;
}

.explore-button:hover {
  background-color: rgba(94, 158, 233, 0.8);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.5);
}

/* Responsive styles for mobile screens */
@media screen and (max-width: 768px) {
  /* Ensure the hero section fills the screen properly */
  .hero {
    padding: 0 1rem; /* Reduce padding on smaller screens */
    height: 100vh; /* Maintain full viewport height */
  }

  /* Make hero content responsive */
  .hero-content h1 {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
    margin-bottom: 1rem; /* Reduce the space below the heading */
  }

  .hero-content p {
    font-size: 1rem; /* Reduce font size for smaller screens */
    max-width: 90%; /* Reduce max-width for better fitting */
    margin-bottom: 20px; /* Adjust the space below the paragraph */
  }

  /* Adjust button size for mobile screens */
  .explore-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480px) {


  .hero-content p {
    font-size: 0.9rem;
  }

  .explore-button {
    font-size: 0.9rem;
    padding: 6px 14px;
  }
}
