/* src/components/OurPartners.css */

.partners-section {
    background-color: #000; /* Dark background for the section */
    padding: 60px 20px;
    color: #e0e0e0; /* Light grey text color for better contrast */
    text-align: center;
    background-image: url(../images/stars-overlay.jpg);
    background-size: cover;
    background-position: center;
  
  }
  
  .partners-section h2 {
    font-size: 2.5rem;
    color: #fff; 
    margin-bottom: 40px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }
  
  .partners-grid {
    display: flex;
    gap: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .partner-card {
    background-color: #1e1e1e; /* Slightly lighter than section background */
    border-radius: 10px;
    padding: 20px;
    width: 250px; /* Increased width for larger cards */
    height: 300px; /* Set a height for uniform size */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
   
  }
  
  .partner-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
  }
  
  .partner-logo {
    background-color: #fff;
    width: 100%; /* Fill the width of the card */
    height: 230px; /* Set a height to maintain consistency */
    object-fit: contain; /* Ensures image fits without cropping */
    margin-bottom: 15px;
    filter: brightness(0.95); /* Slightly dimmed to match dark theme */
  }
  
  .partner-card h3 {
    font-size: 1.3rem;
    color: #e0e0e0;
    font-weight: 500;
    margin-top: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }
  