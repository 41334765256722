/* Dark Theme: Container for the entire component */
.spaceFactsContainer {
    margin-top: 30px;
    padding: 20px;
    text-align: center;
    background-image: url('../images/stars-overlay.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Keeps background fixed while scrolling */
    color: #e4e6ef; /* Light text color */
    position: relative; /* Necessary for overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Dark overlay on the background image */
.spaceFactsContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
    z-index: -1; /* Ensure overlay stays behind content */
}

/* Heading style */
.heading {
    font-size: 2.5rem;
    color: #ffffff; /* White color for the heading */
    margin-bottom: 20px;
    font-weight: bold;
    z-index: 1; /* Ensure heading is above overlay */
}

/* Container for the list of facts */
.factList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    z-index: 1; /* Ensure content is above overlay */
}

/* Individual fact card */
.factItem {
    background-color: #4b3c2f90;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.829);
    width: 80%;
    max-width: 600px;
    transition: transform 0.3s ease;
    color: #e4e6ef; /* Light text inside card */
}

/* Hover effect on each fact card */
.factItem:hover {
    transform: scale(1.05);
}

/* Title of each fact */
.factItemTitle {
    font-size: 1.6rem;
    color: goldenrod;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Description of each fact */
.factItemText {
    font-size: 1.2rem;
    color: #e4e6ef;
}

/* Loading state */
.loading {
    font-size: 1.2rem;
    color: #b0b0b0;
    font-style: italic;
}

/* Spinner loader */
.spinner {
    border: 4px solid #f3f3f3; /* Light border */
    border-top: 4px solid #3498db; /* Blue color for spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Center the spinner */
}

/* Spinner animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Text during loading state */
.loadingText {
    font-size: 1.4rem;
    color: #ffffff;
    margin-top: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Adds a shadow to make it more readable */
    letter-spacing: 1px;
}

/* Responsive Design */

/* For tablets (screens smaller than 768px) */
@media (max-width: 768px) {
    .heading {
        font-size: 2rem;
    }
    
    .factItem {
        width: 90%;
    }

    .factItemTitle {
        font-size: 1.4rem;
    }

    .factItemText {
        font-size: 1rem;
    }

    .loadingText {
        font-size: 1.2rem; /* Adjust size for smaller screens */
    }
}

/* For small screens (phones smaller than 480px) */
@media (max-width: 480px) {
    .heading {
        font-size: 1.6rem;
    }

    .factItem {
        width: 95%;
        padding: 15px;
    }

    .factItemTitle {
        font-size: 1.2rem;
    }

    .factItemText {
        font-size: 0.9rem;
    }

    .loadingText {
        font-size: 1rem; /* Adjust size for phones */
    }
}
