/* src/testimonials.css */

.testimonials-container {
    position: relative;
    background: 
      linear-gradient(
        rgba(0, 0, 0, 0.3),  /* Dark transparent color at the top */
        rgba(0, 0, 0, 0.3)   /* Dark transparent color at the bottom */
      ),
      url('../images/earth.jpg') no-repeat center center/cover; /* Image as the background */
    padding: 80px 20px;
    text-align: center;
    color: #fff; /* White text color for contrast */
}

/* Heading of testimonials section */
.testimonials-heading {
    font-size: 3rem; /* Slightly larger heading */
    color: #fff;
    margin-bottom: 40px;
    font-weight: 700;
    text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.7); /* Added text-shadow for better visibility */
}

/* Container for all testimonial cards */
.testimonials-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Add more space between cards */
}

/* Individual testimonial card */
.testimonial-card {
    background-color: rgba(44, 42, 42, 0.558); /* Dark background for cards */
    color: #fff; /* Text color inside the card */
    border-radius: 15px; /* More rounded corners for elegance */
    padding: 15px;
    width: 280px;
    max-width: 320px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6); /* Darker shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    backdrop-filter: blur(8px); /* Frosted glass effect */
    overflow: hidden;
}

/* Hover effect for cards */
.testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.7); /* Slightly stronger shadow on hover */
}

/* Profile image styling */
.testimonial-image {
    width: 130px; /* Slightly larger image */
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: 4px solid #fff; /* White border around the image */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Slight shadow on image for depth */
}

/* Testimonial message */
.testimonial-message {
    font-size: 1.2rem;
    color: #f0f0f0; /* Light color for message */
    font-style: italic;
    margin-bottom: 20px;
    line-height: 1.5;
}

/* Name of the person giving the testimonial */
.testimonial-name {
    font-weight: bold;
    font-size: 1.1rem;
    color: #fff; /* White text for the name */
    text-transform: uppercase; /* Make the name uppercase */
}

/* Designation styling */
.testimonial-designation {
    font-size: 1rem;
    font-weight: normal;
    color: #dcdcdc; /* Light grey for designation */
    margin-top: 5px;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
    .testimonials-list {
        flex-direction: column; /* Stack the cards on mobile */
        align-items: center;
    }

    .testimonial-card {
        width: 90%; /* Make cards more fluid on mobile */
        margin-bottom: 20px;
    }
}