

.education-partners-section {
    
    padding: 30px 20px;
    color: #e0e0e0; 
    text-align: center;
    background-image: url("../images/stars-overlay.jpg");
    background-size: cover;
    background-position: center;
  
  }


  
  .education-partners-section h2 {
    font-size: 2.5rem;
    color: #fff; 
    margin-bottom: 40px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  }
  
  .education-partners-grid {
    display: flex;
    gap: 140px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .education-card {
    background-color: #272727; 
    border-radius: 10px;
    padding: 20px;
    width: 250px; 
    height: 300px; 
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
  }
  
  .education-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
  }
  
  .education-logo {
    width: 100%; 
    height: 230px; 
    object-fit: contain; 
    margin-bottom: 15px;
    filter: brightness(0.9); 
    background-color: #fff;
  }
  
  .education-card h3 {
    font-size: 1.3rem;
    color: #e0e0e0; 
    font-weight: 500;
    margin-top: 0px;
  }
  
  .esa{
 
    object-fit: cover;
  }