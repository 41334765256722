.olympiad-section {
  position: relative;
  padding: 6rem 2rem;
  background-image: url('../images/stars-bg.jpg');
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.8); /* Dark color overlay */
  background-blend-mode:darken;
  color: #fff;
  text-align: center;
  overflow: hidden;
}



.olympiad-content {
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  padding: 3rem 2rem;
  border-radius: 16px;
  background: rgba(25, 25, 30, 0.9);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.isso-logo{
  width: 400px;
 
  mix-blend-mode:color-dodge;
}

.olympiad-content h2 {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  color: #fefefe;
  font-weight: 600;
  letter-spacing: 1px;
}

/* .ol-details-1 {
  letter-spacing: 8px;
  font-weight: bolder;
} */

.ol-details-2 {
  letter-spacing: 8px;
  font-weight: bolder;
}

.olympiad-content p {
  font-size: 1.15rem;
  line-height: 1.7;
  color: #c9c9c9;
  margin-bottom: 2.5rem;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.benefits-list li {
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 0.6rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #b8b8b8;
  text-align: left;
}

.olympiad-register-btn {
  padding: 0.8rem 2.2rem;
  font-size: 1.15rem;
  color: #f5f5f5;
  background-color: #4a9cdb;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 12px rgba(74, 156, 219, 0.4);
  transition: all 0.3s ease;
}

.olympiad-register-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 18px rgba(74, 156, 219, 0.6);
  background-color: #3d89c1;
}

.astronaut {
  position: absolute;
  right: 2%;
  top: 50%;
  width: 350px;
  transform: translateY(-50%);
  animation: float 3s ease-in-out infinite;
}

.study-image {
  position: absolute;
  left: 4%;
  top: 50%;
  width: 200px;
  transform: translateY(-50%);
  z-index: 0;
}

@keyframes float {
  0%, 100% {
    transform: translateY(-50%) translateY(-10px);
  }
  50% {
    transform: translateY(-50%) translateY(10px);
  }
}

@media screen and (max-width: 1400px) {
  .astronaut {
    display: none;
  }
  .study-image {
    display: none;
  }
}
