

.footer {
    background-color: #000;/* Dark background */
    color: #fff; /* White text color */
    padding: 20px 0; /* Vertical padding */
    text-align: center; /* Center text */
    position: relative; /* Positioning for styling */
  }
  
  .footer-content {
    display: flex; /* Flexbox for layout */
    flex-direction: column; /* Column layout */
    align-items: center; /* Center items */
  }
  
  .footer-links {
    margin-bottom: 10px; /* Space below links */
  }
  
  .footer-links a {
    color: #fff; /* White link color */
    margin: 0 15px; /* Horizontal spacing between links */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth color transition */
  }
  
  .footer-links a:hover {
    color: #5e9fe9; /* Change color on hover */
  }
  
  .footer-branding {
    font-size: 14px; /* Smaller text for branding */
  }
  