/* Ensure the body background is white */
body {
  background-color: #5b5959; /* Set the background color to white */
  margin: 0; /* Remove default body margin */
}

/* Styles for the registration form container */
.registration-form-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff; /* Keep this white */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
h2{
  color: black;
}
/* Other styles remain unchanged */
form {
  display: flex;
  flex-direction: column;
}

label {
  margin: 0.5rem 0 0.2rem;
  color: black;
}

input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid black;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.captcha-container {
  align-self: center;
}
/* Styles for the spinner */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #007bff; /* Blue color for spinner */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px; /* Space between spinner and text */
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Submit button styles */
.submit-btn {
  padding: 0.8rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Add a gap between the spinner and the text */
  transition: background-color 0.3s ease;
}

/* Disable state for button */
.submit-btn[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}


.submit-btn:hover{
  background-color: #5b5959;
}