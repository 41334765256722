/* contact.css */

.contact-page {
    background-image: url('../images/stars-overlay.jpg'); /* Background image */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: #333;
    font-family: 'Poppins', sans-serif;
    padding: 50px 0;
    min-height: 100vh;
    position: relative;
    margin-top: 50px;
}

/* Adding a semi-transparent overlay to ensure text is readable */
.contact-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darker overlay to improve text contrast */
    z-index: -1; /* Ensure overlay is behind the content */
}

/* Contact content section with a darker background for contrast */
.contact-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background with transparency */
    color: #fff; /* Light text for readability */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(229, 226, 226, 0.582);
    z-index: 1;
    position: relative;
}

.contact-content h1 {
    font-size: 3rem;
    text-align: center;
    color: #4da7e7; /* Blue color for heading */
    margin-bottom: 30px;
    text-transform: uppercase;
}

/* Flex container for the contact details and map */
.contact-row {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap; /* Allow wrapping of items */
}

.contact-details {
    flex: 1; /* Allow contact details to take up available space */
}

.contact-details h2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #4da7e7;
    margin-bottom: 10px;
}

.contact-details p {
    font-size: 1.1rem;
    color: #ddd;
    line-height: 1.8;
    margin-bottom: 20px;
}

.contact-details a {
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.contact-details a:hover {
    color: orange;
    transform: scale(1.05);
}

/* Style for the social media links */
.social-links {
    margin-top: 30px;
    text-align: center;
}

.social-links h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #4da7e7;
    margin-bottom: 20px;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-icons a {
    transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons a:hover {
    transform: scale(1.1);
    color: #3498db;
}

.social-icon {
    cursor: pointer;
}

/* Style for the map container */
.map-container {
    flex: 1; /* Allow the map container to take up available space */
    text-align: center;
    min-width: 320px; /* Ensure map is not too small */
}

.map-container h3 {
    font-size: 1.8rem;
    color: #4da7e7;
    margin-bottom: 20px;
}

/* Map box styling */
.map-box {
    width: 100%;
    height: 450px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

/* Responsive Design: For smaller screens, stack the elements vertically */
@media (max-width: 768px) {
    .contact-content {
        padding: 20px;
    }

    .contact-content h1 {
        font-size: 2.5rem;
    }

    .contact-details h2 {
        font-size: 1.4rem;
    }

    .contact-details p {
        font-size: 1rem;
    }

    /* Stack contact details and map on small screens */
    .contact-row {
        flex-direction: column; /* Stack items vertically on small screens */
        gap: 20px; /* Smaller gap between elements */
    }

    /* Adjust the map container size for smaller screens */
    .map-box {
        height: 300px; /* Make the map shorter on mobile */
    }
}
