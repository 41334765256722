.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000;
  color: #fff;
  position: fixed; /* Fixes navbar at the top */
  top: 0; /* Ensures it stays at the top */
  left: 0; /* Align to the left edge */
  right: 0; /* Align to the right edge */
  z-index: 1000; /* Ensures navbar stays above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow for better visibility */
}


  
 .navbar-logo > .logologo{
      margin-left: 20px;
      height: 48px;
      cursor: pointer;
 }

 .logo-text{
          height: 50px;
          filter: invert(1);
          cursor: pointer;
      }

    .navbar-links {
    display: flex;
    list-style: none;
  }
  
  .navbar-links li {
    margin: 0 1rem;
  }
  
  .navbar-links a {
    color: #ddd;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .navbar-btn .sign-up {
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
 
  }
  .navbar-btn .login{
    text-decoration: none;
  }

  
  .navbar-btn .sign-up:hover {
    background-color: #0056b3;
  }
  
  .navbar-links a {
    position: relative;
    color: #ddd;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.25rem 0;
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #fff;
  }
  
  .navbar-links a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px; 
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
  
  .navbar-links a:hover::after {
    width: 100%;
  }

  /* Styling for the new Login button */
.navbar-btn .login {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar-btn .login:hover {
  background-color: #218838;
}

/* Modal overlay for background dimming */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

/* Modal content */
.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  transform: translateY(-20px);
  opacity: 0;
  animation: slideIn 0.4s forwards;
}

/* Styling for the user's name */
.navbar-btn .user-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff; /* White text color */
  margin-right: 1rem; /* Add some space between the name and the logout button */
}

/* Styling for the Logout button */
.navbar-btn .logout-btn {
  padding: 0.5rem 1rem;
  background-color: #dc3545; /* Red color */
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  font-weight: bold;
}

.navbar-btn .logout-btn:hover {
  background-color: #c82333; /* Darker red on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}



/* Smooth fade-in effect for overlay */
@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

/* Smooth slide-down effect for modal content */
@keyframes slideIn {
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

/* Form styling inside modal */
.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin: 0.5rem 0 0.2rem;
}

.modal-content input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Submit button in modal */
.modal-content button[type="submit"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  align-self: center;
  width: 150px;
}

/* Close button styling */
.close-modal {
  padding: 0.5rem 1rem;
  background-color: #dc3545; /* Red color */
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 1rem auto 0; /* Center align by setting auto margin */
  display: block; /* Center align with block display */
}

.close-modal:hover {
  background-color: #c82333; /* Darker red on hover */
}

  /* src/components/Nav.css */

.modal-content {
  background-color: #fff; /* Change this if you have a different background color */
  color: #333; /* Adjust text color as necessary */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.forgot-password {
  margin-top: 10px; /* Space above the link */
  font-size: 0.9em; /* Smaller font size for the link */
}

.forgot-password a {
  color: #007bff; /* Link color */
  text-decoration: none; /* No underline */
  transition: color 0.3s;
}

.forgot-password a:hover {
  color: #0056b3; /* Darker shade on hover */
}
