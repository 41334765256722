/* Policies Page Styling */
.policies-page {
    padding: 40px 10%;
    background-color: #121212; /* Dark background */
    color: white;
    min-height: 100vh; /* Ensure full height */
  }
  
  .policies-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .policies-content h1 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #f39c12; /* Gold color for the main heading */
  }
  
  .policies-content h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 10px;
    color: #ecf0f1; /* Lighter color for subheadings */
  }
  
  .policies-content p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #bdc3c7; /* Lighter text for readability */
    margin-bottom: 20px;
  }
  
  /* Styling the sections for better readability */
  .policies-content section {
    margin-bottom: 40px;
  }
  
  .policies-content ul {
    padding-left: 20px;
    list-style-type: disc;
    color: #bdc3c7;
  }
  
  .policies-content ul li {
    margin-bottom: 10px;
  }
  
  /* Add hover effect for internal links */
  .policies-content a {
    color: #f39c12;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .policies-content a:hover {
    color: #e67e22;
    text-decoration: underline;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .policies-page {
      padding: 40px 5%;
    }
  
    .policies-content h1 {
      font-size: 2.2rem;
    }
  
    .policies-content h2 {
      font-size: 1.6rem;
    }
  
    .policies-content p {
      font-size: 1rem;
    }
  }
  