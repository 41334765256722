/* Global Styles */
html {
  scroll-behavior: smooth;
}

body {
  background-image: url('../images/stars-overlay.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-attachment: fixed; /* Ensures background stays in place */
}

/* Navbar */
.olympiad-details-page__navbar {
  background-color: #000;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.olympiad-details-page__logo img {
  height: 50px;
}

.olympiad-details-page__navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.olympiad-details-page__navbar-links li {
  font-size: 16px;
  position: relative;
}

/* Navbar Links with Hover Underline */
.olympiad-details-page__navbar-links a {
  color: #fff;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.olympiad-details-page__navbar-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4a9cdb; /* Underline color */
  transition: width 0.3s ease;
}

.olympiad-details-page__navbar-links a:hover {
  color: #4a9cdb;
}

.olympiad-details-page__navbar-links a:hover::after {
  width: 100%; /* Underline expands on hover */
}

/* Registration Form Section */
.olympiad-details-page__registration-section {
  background-color: rgba(42, 42, 42, 0.7);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin-top: 60px;
  width: 60%;
  margin: 60px auto;
}

.olympiad-details-page__section-title {
  font-size: 36px;
  color: #4a9cdb;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.olympiad-details-page__registration-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.olympiad-details-page__registration-form .form-group {
  display: flex;
  flex-direction: column;
}

.olympiad-details-page__registration-form label {
  font-size: 18px;
  color: #ddd;
  margin-bottom: 5px;
}

.olympiad-details-page__registration-form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #2a2a2a;
  color: #fff;
}

.olympiad-details-page__registration-form input:focus {
  border-color: #4a9cdb;
  outline: none;
}

.olympiad-details-page__register-btn {
  background-color: #4a9cdb;
  color: #fff;
  border: none;
  padding: 10px 25px;  /* Smaller size */
  font-size: 16px; /* Smaller font size */
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.olympiad-details-page__register-btn:hover {
  background-color: #3a8ab5;
}

/* About Section */
.olympiad-details-page__about-section {
  background-image: url('../images/stars-overlay.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 40px;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.olympiad-details-page__section-title {
  font-size: 36px;
  color: #4a9cdb;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}

.olympiad-details-page__about-icons {
  display: flex;
  justify-content: space-evenly;
  gap: 15px; /* Reduced gap between cards */
  flex-wrap: wrap;
  margin-top: 40px;
}

.olympiad-details-page__about-icon-box {
  background: linear-gradient(to bottom right, #3a3a3a, #1f1f1f); /* Greyish gradient */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  width: 23%; /* Slightly larger fixed width for consistency */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px; /* Reduced margin between cards */
}

.olympiad-details-page__about-icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

.olympiad-details-page__about-icon-box .icon {
  font-size: 50px;
  color: #4a9cdb;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.olympiad-details-page__about-icon-box:hover .icon {
  color: #fff; /* Icon color change on hover */
}

.olympiad-details-page__about-icon-box h3 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 15px;
}

.olympiad-details-page__about-icon-box p {
  font-size: 16px;
  color: #ddd;
  line-height: 1.6;
  margin-bottom: 0;
}

.spinner {
  border: 4px solid transparent;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .olympiad-details-page__about-icons {
    flex-direction: column;
    align-items: center;
  }

  .olympiad-details-page__about-icon-box {
    width: 80%; /* Adjust width for smaller screens */
    margin-bottom: 30px;
  }
}

/* Practice Papers Section */
.olympiad-details-page__practice-section {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.olympiad-details-page__practice-papers-list {
  list-style: none;
  padding-left: 0;
}

.olympiad-details-page__practice-papers-list-item {
  font-size: 18px;
  margin-bottom: 10px;
}

.olympiad-details-page__practice-papers-list-item a {
  color: #4a9cdb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.olympiad-details-page__practice-papers-list-item a:hover {
  color: #fff;
}

/* Footer Section */
.olympiad-details-page__footer {
  background-color: #222;
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  bottom: 0;
  width: 100%;
}

/* Box Design */
.olympiad-details-page__about-box,
.olympiad-details-page__benefits-box {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
}

.olympiad-details-page__about-box .olympiad-details-page__box-title,
.olympiad-details-page__benefits-box .olympiad-details-page__box-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.olympiad-details-page__about-box .olympiad-details-page__box-text,
.olympiad-details-page__benefits-box .olympiad-details-page__box-text {
  font-size: 16px;
  color: #ddd;
  line-height: 1.6;
}

/* Hover Effects on Boxes */
.olympiad-details-page__about-box:hover,
.olympiad-details-page__benefits-box:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
}


.link-style-button {
  background: none;
  color: #87CEFA; /* Adjust to match your link's color */
  text-decoration: underline;
  border: none;
  cursor: pointer;
  font-size: 16px; /* Match the size of the current link */
  font-family: inherit; /* Use the same font as the surrounding text */
  padding: 0;
}

.link-style-button:hover {
  color: #4682B4; /* Slightly darker color on hover */
}