/* Container for the accreditation section */
.accreditation-container {
    background-color: #000; /* Dark background */
    color: white; /* White text */
    padding: 50px 0;
    text-align: center;
    margin-top: 30px;
  }
  
  /* Heading for the section */
  .accreditation-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white; /* White color for the heading */
  }
  
  /* Description text under the heading */
  .accreditation-description {
    font-size: 1.1rem;
    margin-bottom: 40px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
  
  /* Container for the accreditation cards (Using Flexbox now) */
  .accreditation-cards {
    display: flex;
    flex-wrap: wrap; /* Allows cards to wrap onto the next line */
    justify-content: center; /* Centers the cards horizontally */
    gap: 30px; /* Adds space between cards */
    padding: 0 20px;
    margin-top: 20px;
  }
  
  /* Individual card style */
  .accreditation-card {
    background-color: #fdf9f9; /* Dark card background */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for card */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 250px; /* Fixed width for each card */
    max-width: 100%; /* Ensures cards can adjust on smaller screens */
  }
  
  /* Image inside each card */
  .accreditation-image {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensures images are cropped to maintain aspect ratio */
    aspect-ratio: 4/3; /* Aspect ratio for uniform image sizes (e.g., 4:3 ratio) */
  }
  
  /* Hover effects for the cards */
  .accreditation-card:hover {
    transform: translateY(-5px); /* Lift the card slightly */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .accreditation-heading {
      font-size: 2rem;
    }
  
    .accreditation-description {
      font-size: 1rem;
      padding: 0 10px;
    }
  
    .accreditation-cards {
      padding: 0 10px;
    }
  
    .accreditation-card {
      width: 200px; /* Adjust card size for smaller screens */
    }
  }
  