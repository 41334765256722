/* Styling for the about section */
.about-section {
  position: relative;
  padding: 6rem 2rem;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.about-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/stars-overlay.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

/* Styling for the container holding the 4 boxes */
.about-content {
  display: flex;
  flex-wrap: wrap; /* Allow the boxes to wrap when needed */
  justify-content: center; /* Center-align the boxes */
  gap: 2rem; /* Space between the boxes */
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Box styling */
.about-box {
  background: rgba(0, 0, 0, 0.7); /* Darker background for each box */
  padding: 2rem;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(244, 244, 244, 0.5); /* Shadow for depth */
  opacity: 0; /* Initially hidden for animation */
  transform: translateY(20px); /* Initially shifted down */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition */
  animation: fadeInUp 1s ease-out forwards; /* Fade-in and slide-up animation */
  width: 40%; /* Default width for larger screens */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation to each box with a delay */
.about-box:nth-child(1) {
  animation-delay: 0.1s;
}
.about-box:nth-child(2) {
  animation-delay: 0.3s;
}
.about-box:nth-child(3) {
  animation-delay: 0.5s;
}
.about-box:nth-child(4) {
  animation-delay: 0.7s;
}

/* Hover effects to make the boxes interactive */
.about-box:hover {
  transform: scale(1.05); /* Slightly enlarge the box on hover */
  box-shadow: 0 8px 40px rgba(244, 244, 244, 0.7); /* Bigger shadow for hover */
}

/* Styling for the text inside the boxes */
.about-box h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #4da7e7; /* Bright accent color for emphasis */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.about-box p {
  font-size: 1rem;
  line-height: 1.6;
  color: #dcdcdc; /* Light color for text */
}

/* Styling for the icons */
.about-box i {
  font-size: 3rem; /* Icon size */
  color: #fff; /* White icon color */
  margin-bottom: 1rem; /* Space between icon and heading */
  display: block; /* Ensure the icon is on a new line */
  animation: fadeInUp 1s ease-out forwards; /* Fade-in animation for icons */
  opacity: 0; /* Initially hidden */
}

.about-box i:nth-child(1) {
  animation-delay: 0.1s;
}

.about-box i:nth-child(2) {
  animation-delay: 0.3s;
}

.about-box i:nth-child(3) {
  animation-delay: 0.5s;
}

.about-box i:nth-child(4) {
  animation-delay: 0.7s;
}

/* Responsive styling */
@media (max-width: 768px) {
  /* Adjust box width for smaller screens */
  .about-box {
    width: 100%; /* Full width for each box */
    margin-bottom: 2rem; /* Add spacing between boxes */
  }

  .about-box h3 {
    font-size: 1.5rem; /* Adjusted title size for mobile */
  }

  .about-box p {
    font-size: 0.9rem; /* Adjusted paragraph size for mobile */
  }

  .about-box i {
    font-size: 2.5rem; /* Adjust icon size for smaller screens */
  }
}
