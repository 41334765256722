/* src/components/FAQ.css */

.faq-section {
    background-color: #000;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
  }
  
  .faq-section h2 {
    color:#fff;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faq-item {
    background-color: #3c3c3c;
    border-radius: 8px;
    width: 100%; /* Use 100% of the container */
    max-width: 800px; /* Increase max-width for wider display */
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #4c4c4c;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center; /* Align vertically */
  }
  
  .faq-answer {
    padding: 0 15px 15px;
    overflow: hidden; /* Hide the overflow */
    transition: max-height 0.3s ease; /* Smooth transition for max-height */
  }
  
  /* Active state for the answer to control visibility */
  .faq-item.active .faq-answer {
    padding: 15px; /* Add padding when visible for better appearance */
  }
  
  /* Rotation animation for the plus/minus icon */
  .faq-item span {
    display: inline-block; /* Allow the span to be rotated */
    transition: transform 0.3s ease; /* Smooth rotation */
  }
  
  .faq-item.active span {
    transform: rotate(180deg); /* Rotate the icon when active */
  }
  