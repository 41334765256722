/* login.css */
.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full viewport height */
    background-color: #5b5959; /* Light background color */
  }
  
  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px; /* Fixed width for the form */
  }
  
  .login-form h2 {
    margin-bottom: 1rem; /* Spacing below the title */
  }
  
  .login-form label {
    margin: 0.5rem 0 0.2rem; /* Spacing for labels */
  }
  
  .login-form input {
    padding: 0.5rem;
    margin-bottom: 1rem; /* Space between inputs */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Rounded corners */
    width: 100%; /* Full width */
  }
  
  .login-form button {
    padding: 0.5rem;
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer on hover */
  }
  
  .login-form button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  /* Add styles for the spinner animation */
.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Style the button when disabled */
button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
