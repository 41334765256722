/* src/components/UpcomingCourses.css */

.upcoming-courses {
  position: relative; /* Position relative for the pseudo-element overlay */
  padding: 80px 0; /* Adjust top and bottom padding */
  text-align: center;
  width: 100%; /* Ensure the section takes the full width */
  margin: 0; /* Reset margin to eliminate empty space */
  overflow: hidden; /* Hide overflow for the overlay */
}

.upcoming-courses::before {
  content: ''; /* Required for pseudo-element */
  position: absolute; /* Position it absolutely within the section */
  top: 0; /* Cover the top */
  left: 0; /* Cover the left */
  right: 0; /* Cover the right */
  bottom: 0; /* Cover the bottom */
  background-image: url('../images/stars-overlay.jpg'); /* Add your background image */
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */

}

.upcoming-courses h2 {
  font-size: 2em;
  margin-bottom: 50px;
  position: relative; /* Ensure heading is above the overlay */
  z-index: 2; /* Higher z-index to appear above the overlay */
  color: #fff; 
}

.courses-grid {
  display: flex;
 
  justify-content: center;
  gap: 120px;
  flex-wrap: wrap;
  position: relative; /* Ensure grid is above the overlay */
  z-index: 2; /* Higher z-index to appear above the overlay */
}

.course-card {
  background-color: rgba(46, 46, 46, 0.8); /* Use a semi-transparent background to allow some image visibility */
  border-radius: 8px;
  overflow: hidden;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  color: #fff;
}

.course-card:hover {
  transform: scale(1.05);
}

/* Image should fit within the card without cropping */
.course-image {
  width: 100%;
  height: 150px; /* Set a consistent height */
  object-fit: cover; /* Ensures the entire image is visible within the area */
  background-color: #1c1c1c; /* Adds background color to match the card */
}

.course-card:nth-child(3) .course-image {
  object-fit: contain; /* Ensures the entire AI image is visible */
  height: auto; /* Allow height to adjust based on aspect ratio */
  max-height: 150px; /* Limit height to avoid overflow */
  margin-left: auto; /* Align to the right */
}

.course-title {
  font-size: 1em;
  margin: 15px 0;
}

.coming-soon {
  color: #f39c12;
  font-weight: bold;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}
