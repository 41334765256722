/* Importing Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.thankYouContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7fc;
  background-image: url('../images/stars-overlay.jpg'); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  font-family: 'Poppins', sans-serif;
  color: #fff; /* Ensures text is legible against the background */
  padding: 20px;
}

.thankYouContent {
  text-align: center;
  max-width: 600px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.thankYouHeading {
  font-size: 2.5rem;
  color: #1a73e8;
  font-weight: 600;
  margin-bottom: 20px;
}

.thankYouMessage {
  font-size: 1.2rem;
  color: #0c0c0c;
  margin-bottom: 20px;
}

.thankYouFooter {
  font-size: 1rem;
  color: #555;
  font-style: italic;
}

.thankYouButton {
  margin-top: 30px;
  background-color: #1a73e8;
  color: black;
  border: none;
  padding: 12px 25px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.thankYouButton:hover {
  background-color: #155ab7;
}

.thankYouFullForm {
  font-size: 1rem;
  color: black;
  margin-top: 15px;
  font-weight: 600;
  text-align: center;
}
/* Media Queries for responsiveness */

/* Small devices (phones, 600px and below) */
@media screen and (max-width: 600px) {
    .thankYouHeading {
      font-size: 1.8rem; /* Smaller heading for small screens */
    }
  
    .thankYouMessage {
      font-size: 1rem; /* Adjusting message size */
    }
  
    .thankYouContent {
      padding: 20px; /* Less padding for smaller screens */
      width: 90%; /* Reduce width for mobile */
    }
  
    .thankYouFooter {
      font-size: 0.9rem; /* Smaller footer text */
    }
  
    .thankYouButton {
      padding: 10px 20px; /* Smaller button */
      font-size: 0.9rem; /* Adjust font size of button */
    }
  
    .thankYouFullForm {
      font-size: 0.9rem; /* Smaller font size for small screens */
    }
  }
  
  /* Medium devices (tablets, 768px and below) */
  @media screen and (max-width: 768px) {
    .thankYouHeading {
      font-size: 2rem; /* Adjust heading size for tablets */
    }
  
    .thankYouMessage {
      font-size: 1.1rem; /* Slightly smaller message */
    }
  
    .thankYouContent {
      padding: 25px;
      width: 85%; /* Reduced width for tablets */
    }
  
    .thankYouButton {
      padding: 12px 22px; /* Adjust button size */
    }
  }
  
  /* Larger devices (Desktops, 1024px and above) */
  @media screen and (min-width: 1024px) {
    .thankYouHeading {
      font-size: 2.5rem; /* Normal heading size for larger screens */
    }
  
    .thankYouMessage {
      font-size: 1.2rem; /* Normal message size */
    }
  
    .thankYouContent {
      padding: 30px;
      width: 600px; /* Fixed width for larger screens */
    }
  
    .thankYouButton {
      padding: 12px 25px; /* Normal button size */
    }
  }